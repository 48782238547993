import { Redirect, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import React from 'react'
import { useFlags } from 'components/flags/hook'

import PageLoader from 'components/page-loader'

const Entry = Loadable({
  loader: () => import('./components/entry'),
  loading: PageLoader,
})

const List = Loadable({
  loader: () => import('./components/list'),
  loading: PageLoader,
})
const ListDeprecated = Loadable({
  loader: () => import('./components/list-deprecated'),
  loading: PageLoader,
})

export default function AuditsRoute() {
  const flags = useFlags()
  return (
    <Switch>
      <Route component={Entry} exact path="/reports/audits/:id" />
      {flags.rolloutBatchUpdateAudits ? (
        <Route component={List} exact path="/reports/audits" />
      ) : (
        <Route component={ListDeprecated} exact path="/reports/audits" />
      )}
      <Redirect to="/404" />
    </Switch>
  )
}
